<template>
  <div :class="classes" @click="$emit('click')">
    <div class="card-survey__meta">
      <div class="card-survey__date" v-if="data.submissionCount > 0">{{ $t('user-portal.survey_submission_date', { date: submissionDate }) }}</div>
      <div class="card-survey__title">{{ data.title }}</div>
      <div class="card-survey__description">{{ data.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSurvey',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    classes() {
      return {
        'card-survey': true,
        '-is-locked': false
      }
    },

    submissionDate() {
      return this.$basil.i18n.date(new Date(Date.parse(this.data.submissionDate)))
    }
  }
}
</script>
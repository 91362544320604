<template>
  <main 
    :class="classes">
    <!-- Header -->
    <header class="survey__header">
      <navigations-breadcrumb
        class="view-surveys__breadcrumb"
        :children="breadcrumbs" 
      />
    </header>

    <!-- Loading -->
    <div 
      class="layout-feedback__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div class="view-surveys__header" v-if="!loading">
      <div class="view-surveys__texts">
        <h2 class="survey__subtitle">{{ $t('user-portal.surveys_title') }}</h2>
        <div class="survey__subdescription">{{ $t('user-portal.surveys_description') }}</div>
      </div>
    </div>

    <div
      class="view-surveys__body"
      v-if="!loading">
      <card-survey
        v-for="survey in surveys"
        :key="survey.id"
        :data="survey"
        @click="() => showSurvey(survey)"
      />
    </div>
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

import CardSurvey from '../../components/card-survey'

export default {
  name: 'Surveys',

  components: {
    CardSurvey,
    NavigationsBreadcrumb
  },

  inject: [
    '$feedback',
    '$user'
  ],

  data() {
    return {
      loading: false
    }
  },

  computed: {
    breadcrumbs() {
      const ret = []

      if (this.$user.user.isLogged) {
        ret.push({ label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' } })
      }

      ret.push({ label: this.$t('user-portal.surveys_title'), href: '#' })

      return ret
    },

    classes() {
      return {
        'container': true,
        'layout-survey__body': true,
        '-is-loading': this.loading
      }
    },

    surveys() {
      return this.$feedback.feedbacks
    }
  },

  methods: {
    async reset() {
      this.loading = true
      try {
        await this.$feedback.getFreeSurveys()
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    showSurvey(survey) {
      this.$router.push({ name: 'sayl-user-portal.survey', params: { surveyId: survey.id } })
    }
  },

  mounted() {
    this.reset()
  }
}
</script>